var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Berita Acara Pindah Lokasi" } },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 13,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 13
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label: "Internal Contract Number :"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm
                                                .internalContractNumber
                                                ? _vm.dataForm
                                                    .internalContractNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Contract Date :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.contractDate
                                                ? _vm.dataForm.contractDate
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Name :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerName
                                                ? _vm.dataForm.customerName
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Number :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerNumber
                                                ? _vm.dataForm.customerNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Address Old :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerAddressOld
                                                ? _vm.dataForm
                                                    .customerAddressOld
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Address New :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerAddressNew
                                                ? _vm.dataForm
                                                    .customerAddressNew
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 11,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 11
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Old Location :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.oldLocation
                                                ? _vm.dataForm.oldLocation
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "New Location :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.newLocation
                                                ? _vm.dataForm.newLocation
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "BAPL Number :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.baplNumber
                                                ? _vm.dataForm.baplNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Last Print By :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.lastPrintBy
                                                ? _vm.dataForm.lastPrintBy
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  dataSource: _vm.dataList,
                                  columns: _vm.columnsTable,
                                  paginationcustom: false,
                                  defaultPagination: {
                                    showTotal: function(total) {
                                      return _vm.$t("lbl_total_items", {
                                        total: total
                                      })
                                    },
                                    showSizeChanger: true
                                  },
                                  scroll: { x: "calc(700px + 50%)", y: 400 }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24, align: "end" } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: { click: _vm.handleBack }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "vertical-align": "0" },
                                        attrs: { type: "left" }
                                      }),
                                      _vm._v("Back ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.loadingPrint
                                      },
                                      on: { click: _vm.handlePrint }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "vertical-align": "0" },
                                        attrs: { type: "printer" }
                                      }),
                                      _vm._v("Print ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }