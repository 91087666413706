























































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { salesOrderServices } from "@/services/salesorder.service";
import { LineDTODetailICChangeLocation } from "@/models/interface/salesOrder.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import printJS from "print-js";

interface LoadingComponent {
  loadingPrint: boolean;
}

interface DataForm {
  internalContractNumber: string;
  contractDate: string;
  customerNumber: string;
  customerName: string;
  supplierExpeditionAddress: string;
  oldLocation: string;
  customerAddressNew: string;
  newLocation: string;
  baplNumber: string;
  lastPrintBy: string;
  customerAddressOld: string;
}

@Component
export default class CetakPindahLokasi extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "cetakPindahLokasi" });
  params: RequestQueryParamsModel = {};
  id = "";
  dataForm: DataForm = {
    internalContractNumber: "",
    contractDate: "",
    customerNumber: "",
    customerName: "",
    supplierExpeditionAddress: "",
    oldLocation: "",
    customerAddressNew: "",
    newLocation: "",
    baplNumber: "",
    lastPrintBy: "",
    customerAddressOld: "",
  };
  loading: LoadingComponent = {
    loadingPrint: false,
  };
  columnsTable = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 110,
      scopedSlots: { customRender: "no" },
    },
    {
      title: "Unit Code",
      dataIndex: "unitCode",
      key: "unitCode",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Merk",
      dataIndex: "merk",
      key: "merk",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "QTY",
      dataIndex: "qty",
      key: "qty",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
  ];
  dataList: LineDTODetailICChangeLocation[] = [];
  internalContractNo = "";
  idCustomer = "";
  idBranch = "";

  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }

  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
    params += this.checkValue(`,${res["workOrderNumber"]}`);
    params += this.checkValue(`,${res["csfNumber"]}`);
    params += this.checkValue(`,${res["customerName"]}`);
    params += this.checkValue(`,${res["unitCode"]}`);
    params += this.checkValue(
      res["dateFrom"]
        ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
        : null
    );
    params += this.checkValue(
      res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
    );

    return params;
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  getDetailCetakPindahLokasi() {
    salesOrderServices
      .getDetailInternalContractChangeLocation(this.id)
      .then((response) => {
        this.dataList = response.lineDTOS;
        this.dataForm.internalContractNumber = response.icNumber;
        this.dataForm.contractDate = response.contractDate;
        this.dataForm.customerName = response.customerName;
        this.dataForm.customerAddressOld = response.customerAddressOld;
        this.dataForm.customerAddressNew = response.customerAddressNew;
        this.dataForm.oldLocation = response.customerLocationOld;
        this.dataForm.lastPrintBy = response.lastPrintBy;
        this.dataForm.customerNumber = response.customerNumber;
        this.dataForm.newLocation = response.customerLocationNew;
        this.dataForm.baplNumber = response.baplNumber;
      });
  }

  handleBack() {
    this.$router.push(`/sales/pindah-lokasi/list`);
  }

  handlePrint() {
    this.loading.loadingPrint = true;
    salesOrderServices
      .getPrintInternalContractChangeLocation(this.dataForm.baplNumber)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJS(url);
        }
      })
      .finally(() => (this.loading.loadingPrint = false));
  }

  created() {
    this.id = this.$route.params.id;
    this.getDetailCetakPindahLokasi();
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
